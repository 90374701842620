/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';
import {
  createConsumer,
  fetchConsumersByCompany,
  updateConsumer,
  deleteConsumer,
} from 'api/consumers';

export const fetchAllConsumersThunk = createAsyncThunk(
  'consumers/fetchAllConsumers',
  async ({ companyId }) => {
    const { data, metadata } = await fetchConsumersByCompany({
      companyId,
    });
    return {
      data,
      metadata,
    };
  },
);

export const fetchConsumersThunk = createAsyncThunk(
  'consumers/fetchConsumers',
  async ({ companyId, search, alarm }) => {
    const { data, metadata } = await fetchConsumersByCompany({
      companyId,
      search,
      alarm,
    });
    return {
      data,
      metadata,
    };
  },
);

export const createConsumerThunk = createAsyncThunk(
  'consumers/createConsumer',
  async ({ params }) => {
    try {
      const response = await createConsumer(params);
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  },
);

export const updateConsumerThunk = createAsyncThunk(
  'consumers/updateConsumer',
  async ({ params }) => {
    try {
      const response = await updateConsumer(params);
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  },
);

export const deleteConsumerThunk = createAsyncThunk(
  'consumers/deleteConsumer',
  async ({ consumerId }) => {
    try {
      const { data } = await deleteConsumer({ id: consumerId });

      return data;
    } catch (error) {
      const { response } = error;

      if (response?.data?.message) throw new Error(response.data.message);

      throw error;
    }
  },
);

const consumersSlice = createSlice({
  name: 'consumers',
  initialState: {
    allCompanyConsumers: {
      items: [],
    },
    companyConsumers: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    createConsumer: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    updateConsumer: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    deleteConsumer: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  reducers: {
    removeAllCompanyConsumers(state) {
      state.allCompanyConsumers.items = [];
    },
  },
  extraReducers: {
    [fetchAllConsumersThunk.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.allCompanyConsumers = {
        items: data.data,
        resourceStatus: RESOURCE_STATUS.READY,
        alarmsCount: data.metadata,
      };
    },
    [fetchAllConsumersThunk.pending]: state => {
      state.allCompanyConsumers.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAllConsumersThunk.rejected]: state => {
      state.allCompanyConsumers.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchConsumersThunk.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.companyConsumers = {
        items: data.data,
        resourceStatus: RESOURCE_STATUS.READY,
        alarmsCount: data.metadata,
      };

      if (state.allCompanyConsumers.items.length === 0) {
        state.allCompanyConsumers.items = data.data;
      }
    },
    [fetchConsumersThunk.pending]: state => {
      state.companyConsumers.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchConsumersThunk.rejected]: state => {
      state.companyConsumers.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [createConsumerThunk.fulfilled]: state => {
      state.createConsumer.resourceStatus = RESOURCE_STATUS.READY;
      state.createConsumer.error = null;
    },
    [createConsumerThunk.pending]: state => {
      state.createConsumer.error = null;
      state.createConsumer.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [createConsumerThunk.rejected]: state => {
      state.createConsumer.resourceStatus = RESOURCE_STATUS.ERROR;
      state.createConsumer.error = state.error;
    },
    [deleteConsumerThunk.fulfilled]: state => {
      state.deleteConsumer.resourceStatus = RESOURCE_STATUS.READY;
      state.deleteConsumer.error = null;
    },
    [deleteConsumerThunk.pending]: state => {
      state.deleteConsumer.error = null;
      state.deleteConsumer.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deleteConsumerThunk.rejected]: state => {
      state.deleteConsumer.resourceStatus = RESOURCE_STATUS.ERROR;
      state.deleteConsumer.error = state.error;
    },
    [updateConsumerThunk.fulfilled]: state => {
      state.updateConsumer.resourceStatus = RESOURCE_STATUS.READY;
      state.updateConsumer.error = null;
    },
    [updateConsumerThunk.pending]: state => {
      state.updateConsumer.error = null;
      state.updateConsumer.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updateConsumerThunk.rejected]: state => {
      state.updateConsumer.resourceStatus = RESOURCE_STATUS.ERROR;
      state.updateConsumer.error = state.error;
    },
  },
});

const getConsumersSlice = state => state.consumers;

export const getConsumersByCompany = createSelector(
  getConsumersSlice,
  state => state.companyConsumers,
);

export const getAllConsumersByCompany = createSelector(
  getConsumersSlice,
  state => state.allCompanyConsumers,
);

export const { removeAllCompanyConsumers } = consumersSlice.actions;

export default consumersSlice.reducer;
