import axios from 'lib/axios';

export const fetchConsumersByCompany = ({ companyId, search, alarm }) =>
  axios.get('/consumers', {
    params: {
      current_company_id: companyId,
      name: search,
      alarm,
      with_alarms_count: true,
    },
  });

export const createConsumer = ({ companyId, name }) =>
  axios.post('/consumers', {
    company_id: companyId,
    name,
  });

export const updateConsumer = ({ id, companyId, name }) =>
  axios.put(`/consumers/${id}`, {
    company_id: companyId,
    name,
  });

export const deleteConsumer = ({ id }) => axios.delete(`/consumers/${id}`);
