/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';

import {
  fetchGeometries,
  bulkMonitorsUpdate,
  bulkMonitorsZoneUpdate,
  fetchMonitors,
  fetchMapMonitors,
  downloadMonitor,
  fetchAllMonitorsReadings,
  fetchAlarmsCount,
} from 'api/monitor';

export const fetchMonitorsThunk = createAsyncThunk(
  'monitors/fetchMonitors',
  async ({
    orderBy,
    orderDirection,
    page,
    search,
    perPage,
    currentCompanyId,
    zoneIds,
    consumerId,
    alarmFilter,
    userId,
    includeInactive,
  }) => {
    const { data, headers } = await fetchMonitors({
      orderBy,
      orderDirection,
      page,
      search,
      perPage,
      currentCompanyId,
      zoneIds,
      consumerId,
      alarmFilter,
      userId,
      includeInactive,
    });
    return {
      data,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const fetchMapMonitorsThunk = createAsyncThunk(
  'monitors/fetchMapMonitors',
  async ({
    orderBy,
    search,
    currentCompanyId,
    zoneIds,
    consumerId,
    alarmFilter,
    fillLevelLower,
    fillLevelUpper,
    includeInactive,
  }) => {
    const { data } = await fetchMapMonitors({
      orderBy,
      search,
      currentCompanyId,
      zoneIds,
      consumerId,
      alarmFilter,
      fillLevelLower,
      fillLevelUpper,
      includeInactive,
    });
    return {
      data,
    };
  },
);

export const fetchGeometriesThunk = createAsyncThunk(
  'monitors/fetchGeometries',
  () => {
    return fetchGeometries();
  },
);

export const downloadMonitorThunk = createAsyncThunk(
  'monitors/download',
  async ({
    id,
    alarm,
    search,
    zoneId,
    consumerId,
    currentCompanyId,
    includeInactive,
  }) => {
    try {
      const data = await downloadMonitor({
        id,
        alarm,
        search,
        zoneId,
        consumerId,
        currentCompanyId,
        includeInactive,
      });
      return data;
    } catch (err) {
      return err;
    }
  },
);

export const updateMonitorsCompanyThunk = createAsyncThunk(
  'monitors/updateMonitorsCompany',
  async ({ params }) => {
    try {
      const data = await bulkMonitorsUpdate(params);
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const updateMonitorsZoneThunk = createAsyncThunk(
  'monitors/updateMonitorsZone',
  async ({ params }) => {
    try {
      const data = await bulkMonitorsZoneUpdate(params);
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const fetchAllMonitorsReadingsThunk = createAsyncThunk(
  'monitors/fetchAllMonitorsReadings',
  async ({
    orderBy,
    orderDirection,
    page,
    readingType,
    esn,
    perPage,
    startDate,
    endDate,
  }) => {
    const { data, headers } = await fetchAllMonitorsReadings({
      orderBy,
      orderDirection,
      page,
      readingType,
      esn,
      perPage,
      startDate,
      endDate,
    });
    return {
      data,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const fetchAlarmsCountThunk = createAsyncThunk(
  'monitors/fetchAlarmsCount',
  async ({ search, companyId, zoneIds, consumerId, includeInactive }) => {
    const { data } = await fetchAlarmsCount({
      search,
      companyId,
      zoneIds,
      consumerId,
      includeInactive,
    });
    return {
      data,
    };
  },
);

const monitorsSlice = createSlice({
  name: 'monitors',
  initialState: {
    list: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
      alarmsCount: {},
      totalPages: null,
      totalCount: null,
    },
    mapMonitors: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    geometries: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    download: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      value: {},
    },
    updateMonitorsCompany: {
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    updateMonitorsZone: {
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    allMonitorsReadings: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
      totalPages: null,
      totalCount: null,
    },
    alarmsCount: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      data: {},
    },
  },
  reducers: {
    setDownloadStatusIdle(state) {
      state.download.resourceStatus = RESOURCE_STATUS.IDLE;
    },
  },
  extraReducers: {
    [fetchMonitorsThunk.fulfilled]: (state, action) => {
      const { data, headers } = action.payload;
      state.list = {
        totalCount: Number(headers.totalCount),
        totalPages: Number(headers.totalPages),
        items: data,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchMonitorsThunk.pending]: state => {
      state.list.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchMonitorsThunk.rejected]: state => {
      state.list.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchMapMonitorsThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.mapMonitors = {
        items: data,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchMapMonitorsThunk.pending]: state => {
      state.mapMonitors.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchMapMonitorsThunk.rejected]: state => {
      state.mapMonitors.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchGeometriesThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;

      const items = Object.keys(data).map(tankType => {
        const endTypes = Object.keys(data[tankType]).map(endTypeName => ({
          name: endTypeName,
          dimensions: data[tankType][endTypeName],
        }));
        return { tankType, endTypes };
      });

      state.geometries = {
        items,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchGeometriesThunk.pending]: state => {
      state.geometries.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchGeometriesThunk.rejected]: state => {
      state.geometries.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [downloadMonitorThunk.fulfilled]: (state, action) => {
      const data = action.payload;
      state.download = {
        value: data,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [downloadMonitorThunk.pending]: state => {
      state.download.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [downloadMonitorThunk.rejected]: state => {
      state.download.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [updateMonitorsCompanyThunk.fulfilled]: state => {
      state.updateMonitorsCompany.resourceStatus = RESOURCE_STATUS.READY;
    },
    [updateMonitorsCompanyThunk.rejected]: state => {
      state.updateMonitorsCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [updateMonitorsCompanyThunk.pending]: state => {
      state.updateMonitorsCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updateMonitorsZoneThunk.fulfilled]: state => {
      state.updateMonitorsZone.resourceStatus = RESOURCE_STATUS.READY;
    },
    [updateMonitorsZoneThunk.rejected]: state => {
      state.updateMonitorsZone.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [updateMonitorsZoneThunk.pending]: state => {
      state.updateMonitorsZone.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAllMonitorsReadingsThunk.fulfilled]: (state, action) => {
      const { data, headers } = action.payload;
      state.allMonitorsReadings = {
        totalCount: Number(headers.totalCount),
        totalPages: Number(headers.totalPages),
        items: data,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchAllMonitorsReadingsThunk.pending]: state => {
      state.allMonitorsReadings.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAllMonitorsReadingsThunk.rejected]: state => {
      state.allMonitorsReadings.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchAlarmsCountThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.alarmsCount = {
        data,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchAlarmsCountThunk.pending]: state => {
      state.alarmsCount.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAlarmsCountThunk.rejected]: state => {
      state.alarmsCount.resourceStatus = RESOURCE_STATUS.ERROR;
    },
  },
});

export const getMonitors = state => state.monitors.list;
export const getMapMonitors = state => state.monitors.mapMonitors;
export const getGeometries = state => state.monitors.geometries;
export const getDownload = state => state.monitors.download;
export const getAllMonitorsReadings = state =>
  state.monitors.allMonitorsReadings;
export const getAlarmsCount = state => state.monitors.alarmsCount;

export const { setDownloadStatusIdle } = monitorsSlice.actions;

export default monitorsSlice.reducer;
